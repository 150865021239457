import React from 'react';

import { Box, Stack, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';


const ytLink = "https://www.youtube.com/@CantastoriaChoir";
const instaLink = "https://www.instagram.com/cantastoriachoir/";
const fbLink = "https://www.facebook.com/profile.php?id=61559665927878&mibextid=LQQJ4d";

export default function Footer() {
    const navigate = useNavigate();

    const handleTitleClick = () => {
        navigate("/");
    }

    const renderLinks = () => {
        return (
            <Box sx={{ display: "flex" }}>
                <Box flex={1} sx={{ mt: 1, mb: 7 }}>
                    <Stack>
                        <Typography variant="body1" color="primary.light" sx={{ mb: 2, fontWeight: 600 }}>Explore</Typography>
                        <Box sx={{ mb: 2 }}><Link href="/about">About Us</Link></Box>
                        <Box sx={{ mb: 2 }}><Link href="/cantatas">Cantatas</Link></Box>
                        <Box><Link href="/contact">Contact</Link></Box>
                    </Stack>
                </Box>
                <Box flex={1} sx={{ mt: 1 }}>
                    <Typography variant="body1" color="primary.light" sx={{ mb: 2, fontWeight: 600 }}>Follow Us</Typography>
                    <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                        <YouTubeIcon sx={{ mr: 1 }} color="primary" />
                        <Link color="secondary.light" href={ytLink} target ="_blank">YouTube</Link>
                    </Box>
                    <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                        <InstagramIcon sx={{ mr: 1 }} color="primary" />
                        <Link href={instaLink} target ="_blank">Instagram</Link>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FacebookIcon sx={{ mr: 1 }} color="primary" />
                        <Link href={fbLink} target ="_blank">Facebook</Link>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', bgcolor: "primary.dark" }}>
            <Box sx={{ px:4, pt:3, overflow:'clip', display:'flex', flexWrap: "wrap" }}>
                <Box sx={{ width: { xs: "100%", sm: "50%" }, mb:{xs: 4, sm:0} }}>
                    <Typography onClick={handleTitleClick} variant="h4" color="primary.light" sx={{ fontWeight: 800 }}>Cantastoria</Typography>
                </Box>
                <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                    {renderLinks()}
                </Box>
            </Box>
        </Box>
    );
}