export const cantatasContent = [
    {
        title: "All We Like Sheep",
        subtitle: "(or How To Woo A Shepherdess And Wind Up Wishing You Hadn't.)",
        abstract: [
            {
                text: "Amyntas, a shepherd boy, in an effort to woo the fair shepherdess Phyllis, picks some flowers, leaves them for her to find, and hopes for the best.   It works!   They get together and have some fun, but ultimately it doesn’t last, leaving the heartbroken Amyntas to lick his wounds, vowing to never love again.   Well… until the next shepherdess that he likes the look of comes along anyway!"
            },
        ],
        songsIntro: 'Four English Madrigals',
        songs: [
            { bullet: true, text: "Flora Gave Me Fairest Flowers - John Wilbye", link: "https://www.youtube.com/watch?v=xkNlI6VWjk0" },
            { bullet: true, text: "Fair Phyllis I Saw  - John Farmer" , link: "https://www.youtube.com/watch?v=17gm3N1gYO4"},
            { bullet: true, text: "Come Away Sweet Love - Thomas Greaves", link: "https://www.youtube.com/watch?v=Cg1sGPVe1Rc" },
            { bullet: true, text: `Though Amaryllis Dance in Green - William Byrd` , link: "https://www.youtube.com/watch?v=l4kJacQHUjU"},
        ],
        about: [
            { text: `Choral story book video with illustrations by artist Zoe Wood.` }
        ],
        videoId: 'zQ9az6wA5hM',
    },
    {
        title: "Love and Friendship",
        subtitle: "One Text Three Ways",
        abstract: [
            {
                text: "Emily Brontë, author of the novel Wuthering Heights also wrote many celebrated poems.   Among these was a short 3 stanza text titled “Love and Friendship”.   This text compares the fleeting joys of “love” with the enduring solace of “friendship”, opening with statement and question:",
            },
            {
                text: "Love is like the wild rose-briar, friendship like the holly tree.\nThe holly is dark while the rose-briar blooms, but which will bloom most constantly?",
                italic: true,
                indent: true,
            },
            {
                text: "In this cantata we explore a little of the poet’s difficult childhood that contextualizes the ideas advanced in the poem, before exploring three very diverse musical settings of it.    One of these was written by the composer John Ireland, for solo voice and piano, in 1926.    The other two, one for treble voices by Seattle based composer Karen Thomas, and one for mixed choir by Cantastoria’s musical director Stephen Tyler, were both written in 2018 for the 200th anniversary of the poet’s birth.  Both of these contemporary composers were able to contribute their thoughts on all 3 settings to this fascinating project",
            },

        ],

        songsIntro: undefined,
        songs: [
            { bullet: true, text: 'Love and Friendship - Karen Thomas' , link: "https://www.youtube.com/watch?v=OxNALftD_W8"},
            { bullet: true, text: 'Love and Friendship - John Ireland', link: "https://www.youtube.com/watch?v=O42ewFQvKtk"},
            { bullet: true, text: 'Love and Friendship - Stephen Tyler', link: "https://www.youtube.com/watch?v=qZ0HIzcdjFI"},
        ],
        about: [
            { text: 'In this simple production we narration and excerpts from video interviews with the two living composers to introduce each setting and its distinctive elements.' },
        ],
    },
    {
        title: "A Heart's Cry",
        subtitle: "A cantata based on the book \"The Awakening\" by Kate Chopin.",
        abstract: [
            {
                text: `In 1899, author Kate Chopin published The Awakening.   It tells the story of Edna Pontellier and her gradual but ultimately tragic path of awakening to her authentic self and overcoming the expectations that society placed upon her as a woman in the mid-late 19th century.  
                Edna's awakening process begins at a piano concert, where a strong and independent woman plays Chopin (no relation to the author) preludes and Edna is deeply moved, the pianist herself becoming something of an inspiration to Edna to engage what really ignites her passions, without regard for what people may think of her.
                As the story develops, a deep but ill-fated love affair brings to a head a dilemma for Edna between the life she has and the life she wants, and feeling that neither is both attainable and tolerable, ultimately leads to her suicide. 
                The book was panned as scandalous and salacious by contemporary critics for its acknoweldgement and treatment of female sensuality and independence from the patriarchy that defined society at the time.   The author's working career as a writer was effectively ended by the negative reaction of her publishers and critics.
                In due course though it would go on to be recognized as a seminal work within the context of the feminist movement, giving voice to women who didn't find ultimate fulfillment in the expected roles of dutiful wife and doting mother.    Within a couple of decades major social changes played out that would give women the right to self-ownership (not be considered property of their husbands), the right to vote, the right to equal work and pay, and much more.`,
            },
        ],

        songsIntro: undefined,
        songs: [
            { bullet: true, text: 'The Heart\'s Cry - Bill Whelan' },
            { bullet: true, text: 'I Know Why the Caged Bird Sings - Stefan Thompson' },
            { bullet: true, text: 'Remember Me - Stephen Chatman' },
            { bullet: true, text: 'The Awakening - Travis Nance' },
            { bullet: true, text: 'Lay A Garland - Robert Lucas de Pearsall' },
        ],
        about: [
            {
                text: `In this cantata we tell Edna's story and a little of Kate's.   We premiere a new piece by Travis Nance that was inspired by the book and was the inspiration for us to write this cantata around.   The underscore music between the choral pieces is all Chopin in a nod to the initial impetus to Edna's awakening.` 
            },
        ],
    },
    // {
    //     title: "On The Growth of A Poet’s Mind",
    //     subtitle: "The life and work of William Wordsworth  ",
    //     abstract: [
    //         {
    //             text:`The English Romantic poet William Wordsworth wrote an epic poem called The Prelude that across 14 books describes his life and development as a poet.   This biographical documentary cantata tells the story of this development featuring readings and choral settings of several of his best known works along the way.`,
    //         },
    //     ],

    //     songsIntro: undefined,
    //     songs: [
    //         { bullet: true, text: 'Daffodils - Stephen Tyler' },
    //         { bullet: true, text: 'Composed Upon Westminster Bridge - Bob Chilcott' },
    //         { bullet: true, text: 'Our Birth Is But A Sleep - Russel Wilson' },
    //         { bullet: true, text: 'Responsorial I - Eric Barnum' },
    //     ],
    //     about: [
    //         { text: 'Narration and visuals depicting the places that inspired the poems cut with choral performance of the musical items.' },
    //     ],
    // },

    // {
    //     title: "O Magnum Mysterium",
    //     subtitle: "One Text Three Ways",
    //     abstract: [
    //         {
    //             text:`In 1994,  Norwegian composer Morten Lauridsen exploded onto the choral scene with his distinctive and lush choral sound with its sweeping melodic lines and rich, shimmering harmony, a superb example of which being his glorious setting of the Christmas themed Gregorian Chant text about the wonder of the nativity scene: O Magnum Mysterium.`,
    //         },
    //         {
    //             text:`In this production in our “1 Text 3 Ways” series we look at this setting and compare / contrast with two other settings of the same text, also well established classics in the choral literature.    That by French Composer, Francis Poulenc dating from the early 1950s, part of a fabulous set of four Chistmas motets, and the much older setting by Tomás Luis de Victoria, penned in 1572.`,
    //         },               
    //     ],

    //     songsIntro: undefined,
    //     songs: [
    //         { bullet: true, text: 'O Magnum Mysterium - Tomás Luis de Victoria' },
    //         { bullet: true, text: 'O Magnum Mysterium - Francis Poulenc' },
    //         { bullet: true, text: 'O Magnum Mysterium - Morten Lauridsen' },

    //     ],
    //     about: [
    //         { text: 'For this production we collaborate with 3 visual artists to create original artworks depicting this classic nativity scene, each interpretation based on one of the three settings featured.   We follow the process of the creation of these pieces as we hear them performed by the choir.' },
    //     ],
    // },


];

export const aboutContent = {
    id:"about",
    bodyText: [
        { text: "Typically 10-15 minutes long and featuring 3 to 4 choral pieces, our cantatas tell short stories." },
        { text: "We are passionate about collaboration with creative people and organizations in other artistic disciplines to tell our stories in creative and compelling ways.   We love to work with, and be inspired by, other musicians, dancers, painters, sculptors, actors, mime artists, poets, composers, film producers, illustrators, animators, trapeze artists, balloon wranglers… if it’s fun and creative, we’re down!" },
        { text: "We are based in Austin, TX and rehearse on Wednesday evenings." }
    ],
};

export const joinUsContent = {
    id:"joinus",
    title: "Join Us",
    bodyText: [
        { text: "We love to welcome new Cantastorians!   We ask that our singers have:" },
        { text: "Ability to read music and either sight read well or commit to put in the work on your own time between rehearsals so that we can work at a fairly brisk pace when we are together.   To support weaker sight readers who are willing to put in some personal time to compensate, we provide resources to help you learn your part and will assist you in any way we can.", bullet: true },
        { text: "Ability to sing at a good choral standard.   You do not need to be a great soloist or even have a ton of previous chamber choir experience, but we do look for you to have a solid core vocal  technique, ability to produce a range tonal colors, to sing accurately in terms of rhythm and intonation, and with musical empathy for those around you so that we can craft a unified and expressive ensemble.", bullet: true },
        { text: "Willingness to commit to regular rehearsal attendance.", bullet: true },
        { text: "We do not audition new members up front.   If you are interested just send us a note using the Contact page or by emailing us at info@cantastoria.org.   We’ll get back to you with the info you need to be ready to attend your first rehearsal.   We do a fairly simple confirmatory audition after the first 4-6 weeks of attending rehearsals.   It’s not a scary or intimidating thing, so don’t worry about it!   If you want to come sing with us, we’d love to welcome you to our next rehearsal and take it from there !" },
        { text: "Hope to see you at a rehearsal soon !" }
    ],
    buttonText: 'Join Us'
};

export const homePageAboutContent = {
    id:"homeAbout",
    title: "Once Upon A Choir",
    bodyText: [
        {
            text: `Cantastoria uses choral music, ancient and modern, to tell stories in the form of cantatas that we write and produce for video or live performance.  We are passionate about collaborating with artists in other disciplines to tell stories in varied and compelling ways.`
        }
    ],
    buttonText: "Learn more"
}
